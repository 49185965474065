import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { StyledLink } from './design-system'
import Content, { HTMLContent } from './common/Content'

const S = {}

S.Wrapper = styled.div`
  position: relative;
  width: 100%;
  object-fit: cover;
`

S.Overlay = styled.div`
  position: absolute;
  background-image: linear-gradient(#000, rgba(0, 0, 0, 0));
  opacity: 1;
  width: 100%;
  height: 20vh;
  z-index: 10;
`

S.FittedImg = styled(Img)`
  width: 100%;
  height: 50vh;
  object-fit: cover;
`

S.ContentContainer = styled.div`
  max-width: 768px;
`

S.Header = styled.div`
  color: #555;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 1rem;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    padding: 4rem 4.5rem;
  }
`

S.Date = styled.h6`
  font-family: ${props => props.theme.fontSerif};
  font-weight: 400;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 16px;
  }
`

S.Title = styled.h1`
  font-family: ${props => props.theme.fontSerif};
  font-weight: 500;
  font-size: 28px;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 40px;
  }
`

S.Subtitle = styled.h2`
  font-family: ${props => props.theme.fontSerif};
  font-weight: 400;
  font-size: 20px;
  font-style: italic;
  margin-bottom: 1em;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 28px;
  }
`

S.Content = styled.div`
  color: #555;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;

  div {
    max-width: 768px;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${props => props.theme.fontSerif};
    margin-bottom: 2.5rem;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
  }

  h3 {
    font-size: 22px;
    font-weight: 500;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
  }

  h6 {
    font-size: 16px;
    font-weight: 400;
  }

  p,
  div {
    font-family: ${props => props.theme.fontPrimary};
    font-size: 14px;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    padding: 0rem 4.5rem;
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 36px;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 28px;
    }
    h5 {
      font-size: 24px;
    }
    h6 {
      font-size: 20px;
    }
    p,
    div {
      font-size: 16px;
    }
  }
`

S.Source = styled.p`
  font-style: italic;
  font-family: ${props => props.theme.fontPrimary};
  font-size: 14px;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    font-size: 16px;
  }
`

S.Link = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: ${props => props.theme.color.black};
  font-size: ${props => (props.fontSize ? props.fontSize : props.theme.fontSizes[4])};

  border-bottom: 1px solid #c69f6e;
`

export default class NewsContent extends React.Component {
  render() {
    const {
      content,
      image,
      title,
      subtitle,
      date,
      link,
      type
    } = this.props

    return (
      <S.Wrapper id="gallery">
        <S.Overlay />
        <S.FittedImg fluid={image.childImageSharp.fluid} alt={title}/>
        <S.Header>
          <S.Date>{date} / {type}</S.Date>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.Header>
        <S.Content>
          <HTMLContent className="content" content={content} />
          {link &&
            <div className="content">
              <S.Source>Originally published at <S.Link href={link}>{link}</S.Link></S.Source>
            </div>
          }
        </S.Content>
      </S.Wrapper>
    )
  }
}
