import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import NewsContent from '../components/NewsContent'

export const NewsPageTemplate = ({
  showInstagram,
  showStore,
  title,
  subtitle,
  link,
  date,
  image,
  content,
  type,
}) => {
  return (
    <React.Fragment>
      <Navbar
        showInstagram={showInstagram}
        showStore={showStore}
        listenToScroll={true}
        isTop={true}
      />
      <NewsContent
        title={title}
        subtitle={subtitle}
        content={content}
        image={image}
        date={date}
        link={link}
        type={type}
      />
      {/* <PostContent content={content} /> */}
    </React.Fragment>
  )
}

const NewsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { html } = data.markdownRemark
  const { frontmatter: indexFrontmatter } = data.indexPage

  return (
    <Layout>
      <NewsPageTemplate
        showInstagram={indexFrontmatter.showInstagram}
        showStore={indexFrontmatter.showStore}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        link={frontmatter.link}
        image={frontmatter.image}
        date={frontmatter.date}
        content={html}
        type={frontmatter.type}
      />
    </Layout>
  )
}

NewsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default NewsPage

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        type
        subtitle
        link
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    indexPage: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        showInstagram
        showStore
      }
    }
  }
`
